
import { ClaimReview, Factcheck } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FactcheckArticleCMSCard extends Vue {
  @Prop({ required: true }) article!: Factcheck;


  get loaded(): boolean {
    return this.claimReview != null && this.partners !== null
  }

  get claimReview(): ClaimReview | null {
    const cr = this.article.Claim?.ClaimReviews[0]
    if (cr) return cr
    return null;
  }

  get partners(): string {
    // return this.claimReview?.Partner?.name || 'Onbekende partner';
    return this.article.Claim?.ClaimReviews.map(e => e.Partner?.name || 'Onbekende partner').join(', ') || 'Onbekende partner';
    //return this.$store.getters[PARTNER_GETTERS.GET_PARTNER](this.claimReview?.PartnerId).name;
  }
}
